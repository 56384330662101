import React from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import BuyersSlider from "./BuyersSlider";
import BuyersSliderMobile from "./BuyersSlider_Mobile";

import * as classes from "./Buyers.module.css";

import { navigate } from "@reach/router";


const Buyers = () => {


    const navCatsState = useSelector(
        state => state.menuReducer.navCats,
        shallowEqual
    );

    const isMobileState = useSelector(
        state => state.mainReducer.isMobile,
        shallowEqual
    );

    const loginNameState = useSelector(
        state => state.loginReducer.loginName,
        shallowEqual
    );

    const handleLogin = () => {
        document.getElementById("login-icon-btn").click();
    };

    // const handleSignup = () => {
    //     navigate("https://app.dibizglobal.com/signup")
    // }

    return (
        <>
            <div className={classes.slideshow_container}>
                {
                    isMobileState ? <BuyersSliderMobile /> : <BuyersSlider />
                }
            </div>
            <div className={classes.wrapper}>
                {/* <h3 className={classes.title}><span>Equal benefits</span> for Buyers and Suppliers</h3>
                <p className={classes.desc}>Are you finding it hard to find and source authentic certified and sustainable products? DIBIZ is the right place to start with. Here, you can find verified suppliers and buyers on a global scale and start interacting with them. Reach out to us for more information.</p> */}
                {/* <div className={classes.section_one}>
                    <div className={classes.section_one__one} >
                        <img src="https://ik.imagekit.io/ofb/dbz/illustration_home_6yHjxDDFB.png" alt="Image" />
                    </div>
                    <div className={classes.section_one__two}>
                        <h3>DIBIZ for <span> {" "} Buyers</span> </h3>
                        <div className={classes.desc__one}>Find and trade your products on one marketplace</div>
                        <p className={classes.desc__two}>Tired of searching for certified sustainable products that cannot give you a guaranteed traceability. DIBIZ offers a new way to search and identify suppliers and connect them with ideal buyers.</p>
                        <ul className={classes.desc__three}>
                            <li>Find best certified products and organizations </li>
                            <li>Brief understanding of the company before reaching out to them </li>
                            <li>Reach out to them, discuss, and negotiate </li>
                            <li>Trade through DIBIZ (optional)</li>
                            <li>Access to global and regional markets</li>
                        </ul>
                        <a>
                            <i className="material-icons-outlined">
                                east
                            </i>
                            Learn more
                        </a>
                        <button>Register with DIBIZ</button>
                    </div>
                </div> */}
                <div className={classes.section_two}>
                    <div className={classes.section_two__one}>
                        {/* <h3>DIBIZ for <span>{" "} Sellers</span> </h3>
                        <div className={classes.desc__one}>Scale your market reach with your certified sustainable products</div>
                        <p className={classes.desc__two}>Discover new ways of digital distribution of your certified products. Sell more of your sustainable products for a premium to discerning customers. Receive request for quotes from verified buyers and start a new relationship.</p>
                        <div className={classes.btn__wrapper}>
                            <button>Register with DIBIZ</button>
                            <div className={classes.login}>
                                Existing Customer?
                                <a href="javascipt:void(0)">Login from here</a>
                            </div>
                        </div> */}
                        <h5 >Key Features</h5>
                        {/* <div className={classes.desc__three}>Find and trade your products on one marketplace</div> */}
                        <ul className={classes.desc__four}>
                            <li>
                                <div className={classes.desc__four__item}>
                                    <img src="https://ik.imagekit.io/ofb/dbz/Path_815_2x_Spb6Nv40N.png" alt="Icon" />
                                    Wide range of products and suppliers
                                </div>

                                <p>Search and filter out from wide network of products and suppliers in the marketplace.</p>
                            </li>
                            <li>
                                <div className={classes.desc__four__item}>
                                    <img src="https://ik.imagekit.io/ofb/dbz/Information_2x_8pOPytnu5z.png" alt="Icon" />
                                    Transparent product information
                                </div>
                                <p>Use the transparent product information available to evaluate and decide</p>
                            </li>
                            <li>
                                <div className={classes.desc__four__item}>
                                    <img src="https://ik.imagekit.io/ofb/dbz/verify_2x_qmzzcIUAwS.png" alt="Icon" />
                                    Verified Suppliers and Buyers
                                </div>
                                <p>Both suppliers and buyers are verified by DIBIZ on onboarding building the credibility factor.</p>
                            </li>
                            <li>
                                <div className={classes.desc__four__item}>
                                    <img src="https://ik.imagekit.io/ofb/dbz/negotiation_2x_66W81kKO9.png" alt="Icon" />
                                    Request and negotiate deals
                                </div>
                                <p>Negotiate deals using the chat system to your advantage and use the information for any future use.</p>
                            </li>
                            <li>
                                <div className={classes.desc__four__item}>
                                    <img src="https://ik.imagekit.io/ofb/dbz/Group_326_2x_nQEx_9dLyI.png" alt="Icon" />
                                    Find your favourite Suppliers
                                </div>
                                <p>Reach out to your favourite brand and built your business relationship.</p>
                            </li>
                            <li>
                                <div className={classes.desc__four__item}>
                                    <img src="https://ik.imagekit.io/ofb/dbz/partner_Location_2x_ZKEO536OS1.png" alt="Icon" />
                                    Find a new partner in your location
                                </div>
                                <p>Search and filter out a new partner in your location.</p>
                            </li>
                        </ul>
                        {
                            loginNameState === "" && (
                                <div className={classes.section_two__one}>
                                    <div className={classes.btn__wrapper}>
                                        <button className={classes.button} >
                                            <a href="https://app.dibizglobal.com/signup">Register with DIBIZ</a>
                                        </button>
                                        <div className={classes.login}>
                                            Existing Customer? 
                                            <span onClick={handleLogin}> Login from here</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div className={classes.section_two__two}>
                        <img src="https://ik.imagekit.io/ofb/dbz/Group_327_u822chRlF.png" alt="Image" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Buyers;
