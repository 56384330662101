import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import SearchHelper from "@components/AC-Header/SearchHelper";

import * as classes from "./Buyers.module.css";

// import { useStaticQuery, graphql } from "gatsby";
// import { Link } from "gatsby";
// import SwipeableViews from "react-swipeable-views";
// import { autoPlay } from "react-swipeable-views-utils";
// import Img from "gatsby-image";


// import { I18nContext } from "@/i18n/index";
// import sdfsdf from "../header/SearchHelper";

// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const BuyersSlider = () => {

  // const { langCode } = React.useContext(I18nContext);

  // const navCatsState = useSelector(
  //   state => state.menuReducer.navCats,
  //   shallowEqual
  // );

  const loginNameState = useSelector(
    state => state.loginReducer.loginName,
    shallowEqual
  );

  const handleLogin = () => {
    document.getElementById("login-icon-btn").click();
  };

  return (
    <>
      <div id="homeBanner">
        <div className="homeBanner-title">
          Looking for buying sustainable certified products?
        </div>
        <div className="homeBanner-desc">
          DIBIZ offers a new way to search and identify suppliers and connect them with ideal buyers.
          Lookout for the best grade and certified products in the market and establish new relationships.
          Let's explore the new way to trade using DIBIZ.
        </div>
        <SearchHelper />
      </div>

      {/*<AutoPlaySwipeableViews
        axis={"x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {tutorialSteps.map((step, index) => (
          <div key={index}>
            <div className="sliderBtns">
              <p>Your  Curbside Pickup  Marketplace</p>
              <Link to={PREVIEW + "/farmers-markets"}>
                Farmer's Markets
              </Link>
              <Link to={PREVIEW + "/shop/retail-store"}>Retail Stores</Link>
              <Link to={PREVIEW + "/shop/restaurants"}>Restaurants</Link>
              <Link to={PREVIEW + "/shop/services"}>Services</Link>
            </div>
            {Math.abs(activeStep - index) <= 2 ? (
              <img
                className="BannerDeskMobile"
                src={step.imgPath}
                alt={step.label}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="dots"
        activeStep={activeStep}
        nextButton=""
        backButton=""
        style={{
          transform: "translateY(-30px)",
          position: "absolute",
          background: "transparent",
          margin: "0 auto",
          display: "flex",
          justifyContent: "center",
          width: "100%"
        }}
      />*/}
    </>
  );
};

export default BuyersSlider;
