// extracted by mini-css-extract-plugin
export var wrapper = "Buyers-module--wrapper--mOCo6";
export var slideshow_container = "Buyers-module--slideshow_container--00E2-";
export var title = "Buyers-module--title--91D6c";
export var desc = "Buyers-module--desc--3wx7Y";
export var section_one = "Buyers-module--section_one--4NiW5";
export var section_one__one = "Buyers-module--section_one__one--bSwFn";
export var section_one__two = "Buyers-module--section_one__two--9VgUe";
export var desc__one = "Buyers-module--desc__one--QwYqL";
export var desc__two = "Buyers-module--desc__two--6alrU";
export var desc__three = "Buyers-module--desc__three--++gMz";
export var section_two = "Buyers-module--section_two--lB2yi";
export var section_two__two = "Buyers-module--section_two__two--aV3ij";
export var section_two__one = "Buyers-module--section_two__one--DeTQ7";
export var btn__wrapper = "Buyers-module--btn__wrapper--+wcWD";
export var login = "Buyers-module--login--W0Bvu";
export var button_explore = "Buyers-module--button_explore--oyMH8";
export var desc__four = "Buyers-module--desc__four--PhAgr";
export var desc__four__item = "Buyers-module--desc__four__item--v0ibR";