import React from "react";
import { useSelector, shallowEqual } from "react-redux";
// import SearchHelper from "../AC-Header/SearchHelper";
import { useStaticQuery, graphql } from "gatsby";

import * as classes from "./Buyers.module.css";

import { navigate } from "@reach/router";

const BuyersSlider_Mobile = () => {

  // const data = useStaticQuery(graphql`
  //   query {
  //     img2: file(relativePath: { eq: "slider/slider2_en.jpg" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 1600) {
  //           ...GatsbyImageSharpFluid_withWebp
  //         }
  //       }
  //     }
  //   }
  // `);

  const navCatsState = useSelector(
    state => state.menuReducer.navCats,
    shallowEqual
  );

  const loginNameState = useSelector(
    state => state.loginReducer.loginName,
    shallowEqual
  );

  const handleLogin = () => {
    document.getElementById("login-icon-btn").click();
  };

  const handleSignup = () => {
    navigate("https://app.dibizglobal.com/signup")
  }

  return (
    <>
      <div id="homeBanner__mobile">
        <div className="homeBanner-title__mobile">
          Looking for buying sustainable certified products?
        </div>
        <div className="homeBanner-desc__mobile">
          DIBIZ offers a new way to search and identify suppliers and connect them with ideal buyers.
          Lookout for the best grade and certified products in the market and establish new relationships.
          Let's explore the new way to trade using DIBIZ.
        </div>
        {
          loginNameState === "" && (
            <div className={classes.section_two__one}>
              <div className={classes.btn__wrapper}>
                <button onClick={handleSignup}>Register with DIBIZ</button>
                <div className={classes.login}>
                  Existing Customer?
                  <span onClick={handleLogin}>Login from here</span>
                </div>
              </div>
            </div>
          )
        }
        {/* <SearchHelper />  */}
      </div>
    </>
  );
};

export default BuyersSlider_Mobile;
